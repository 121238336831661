<template>
  <div class="examine_container">
    <div class="header">
      <van-grid
        :border="false"
        :column-num="4"
      >
        <van-grid-item>
          <van-image
            src="https://peis.ycrmyy.net/wxxcx/wx/profession.png"
            class="img"
          />
          <span>入职套餐</span>
        </van-grid-item>
        <van-grid-item>
          <van-image
            src="https://peis.ycrmyy.net/wxxcx/wx/women.png"
            class="img"
          />
          <span>女士专享</span>
        </van-grid-item>
        <van-grid-item>
          <van-image
            src="https://peis.ycrmyy.net/wxxcx/wx/young.png"
            class="img"
          />
          <span>中青年体检</span>
        </van-grid-item>
        <van-grid-item>
          <van-image
            src="https://peis.ycrmyy.net/wxxcx/wx/oldperson.png"
            class="img"
          />
          <span>中老年体检</span>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="search">
      <div class="screen">
        <van-button
          type="info"
          @click="screenClick"
        >筛&nbsp;选</van-button>
      </div>
      <van-search
        v-model="searchStr"
        show-action
        placeholder="请输入套餐名称"
        @input="onInput"
        @clear="onClear"
        @cancel="onCancel"
      />
    </div>
    <van-popup
      class="popup"
      v-model="screen"
      position="right"
      @close="cancel"
      :style="{ height: '100%' ,width:'70%'}"
    >
      <div class="title">
        分&nbsp;类&nbsp;列&nbsp;表
      </div>
      <div class="popuplist">
        <div
          :class="item.checked?'popupitem selected':'popupitem'"
          v-for="(item,index) in popuplist"
          :key="index"
          @click="selectScreenClick(index)"
        >
          {{item.name}}
        </div>
      </div>
      <div class="confirm">
        <van-button
          type="primary"
          @click="cancel"
        >取&nbsp;消</van-button>
        <van-button
          type="info"
          @click="confirm"
        >确&nbsp;定</van-button>
      </div>
    </van-popup>
    <div
      class="result"
      v-if="resultShow"
    >
      <van-cell
        v-for="(item,index) in 10"
        title="驾驶员体检"
        :key="index"
        icon="user-o"
        is-link
        @click="navDetail"
      />
    </div>
    <div
      class="list"
      v-if="!resultShow"
    >
      <div
        class="item"
        v-for="(item,index) in 10"
        :key="index"
        @click="navDetail"
      >
        <van-image
          width="110"
          height="110"
          src="https://peis.ycrmyy.net/wxxcx/wx/tj_package.jpg"
          fit="cover"
        ></van-image>
        <div class="content">
          <div class="title">驾驶员体检</div>
          <div class="info">套餐简介：</div>
          <div class="num">
            <van-tag
              plain
              type="primary"
            >1个项目</van-tag>
          </div>
          <div class="price">
            <span>&yen;30.00</span>
            <s>&yen;30.00</s>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Examine",
  data() {
    return {
      searchStr: "",
      resultShow: false,
      screen: false,
      popuplist: [
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        },
        {
          name: "入职套餐",
          checked: false
        },
        {
          name: "女士专享",
          checked: false
        },
        {
          name: "中青年体检",
          checked: false
        },
        {
          name: "中老年体检",
          checked: false
        }
      ]
    };
  },
  created() {},
  methods: {
    onInput(value) {
      this.searchStr = value.trim();
      if (this.searchStr.length !== 0) {
        this.resultShow = true;
      } else {
        this.resultShow = false;
      }
    },
    onCancel() {
      this.searchStr = "";
      this.resultShow = false;
    },
    onClear() {
      this.searchStr = "";
      this.resultShow = false;
    },
    screenClick() {
      this.screen = true;
    },
    selectScreenClick(index) {
      this.popuplist[index].checked = !this.popuplist[index].checked;
    },
    cancel() {
      this.screen = false;
      this.popuplist.forEach(item => {
        item.checked = false;
      });
    },
    confirm() {
      this.screen = false;
      this.popuplist.forEach(item => {
        item.checked = false;
      });
    },
    navDetail() {
      this.$router.push({
        path: "/examine/detail"
      });
    }
  }
};
</script>
<style scope>
.examine_container {
  padding-bottom: 60px;
}
.examine_container .header {
  height: 250px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.examine_container .header .van-grid-item {
  height: 250px;
}
.examine_container .header .van-grid-item__content {
  background-color: #f9f4ee;
}
.examine_container .header .img {
  width: 150px;
  height: 150px;
}
.examine_container .header span {
  font-size: 30px;
  text-align: center;
}
.examine_container .search {
  position: sticky;
  top: 250px;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  border-bottom: 10px solid #eee;
  background-color: white;
}
.examine_container .search .screen {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 20%;
  font-size: 36px;
}
.examine_container .search .screen .van-button {
  height: 70px;
  width: 120px;
  font-size: 36px;
}
.examine_container .search .van-search {
  height: 100%;
  width: 80%;
  padding-left: 0;
}
.examine_container .search .van-cell,
.examine_container .search .van-search__action {
  font-size: 36px;
}
.examine_container .search .van-field__control {
  height: 60px;
}
.examine_container .search .van-icon {
  font-size: 40px;
  line-height: 60px;
}
.examine_container .popup .title {
  position: sticky;
  top: 0;
  left: 0;
  height: 100px;
  background-color: skyblue;
  text-align: center;
  line-height: 100px;
  color: white;
}
.examine_container .popup .popuplist {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.examine_container .popup .popuplist .popupitem {
  background-color: #eee;
  color: gray;
  padding: 20px;
  border-radius: 10px;
  font-size: 40px;
  margin: 20px 0;
}
.examine_container .popup .popuplist .selected {
  background-color: #07c160;
  color: white;
}
.examine_container .popup .confirm {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 130px;
  background-color: white;
}
.examine_container .popup .confirm .van-button {
  font-size: 40px;
}
.examine_container .result .van-cell__title {
  font-size: 36px;
  line-height: 56px;
}
.examine_container .result .van-cell__left-icon,
.examine_container .result .van-cell__right-icon {
  font-size: 36px;
  line-height: 56px;
  color: #1989fa;
}
.examine_container .list .item {
  display: flex;
  padding: 20px;
  border-bottom: 10px solid #eee;
}
.examine_container .list .item .content {
  margin-left: 10px;
  flex: 1;
}
.examine_container .content .title {
  font-size: 40px;
  margin-top: 10px;
}
.examine_container .content .info {
  font-size: 36px;
  color: #bbb3b3;
  margin-top: 10px;
  line-height: 60px;
}
.examine_container .content .num {
  margin: 20px 0;
  font-size: 36px;
}
.examine_container .content .num .van-tag {
  font-size: 30px;
  height: 50px;
}
.examine_container .content .price {
  font-size: 40px;
  color: red;
}
.examine_container .content .price s {
  color: rgb(187, 179, 179);
  margin-left: 10px;
}
</style>